<template>
  <div class="PolicyBox">
    <h2>《悦淘隐私政策》2022年10月25日生效版本</h2>
    <p>
      尊敬的悦淘用户：我们对《悦淘隐私政策》进行了更新。此版本《悦淘隐私政策》的更新主要集中在：
      <strong>悦淘接入的第三方SDK情况更新；个人信息含义及示例更新。</strong>
    </p>
    <h3>【特别提示】</h3>
    <p>
      <strong>
        本政策仅适用于悦淘网络科技（北京）有限公司及其关联公司（包括但不限于悦淘网络科技（北京）有限公司，以下或称“我们”或“悦淘”）提供的产品和服务及其延伸的功能（以下简称“悦淘服务”），包括悦淘APP、网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。
      </strong>
    </p>
    <p>
      <strong>
        请仔细阅读《悦淘隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《悦淘隐私政策》中的联系方式咨询我们。
      </strong>
    </p>
    <p>版本更新日期：2022年10月25日</p>
    <p>版本生效日期：2022年10月25日</p>
    <p>如您有任何疑问、意见或建议，您可通过以下联系方式与我们联系：</p>
    <p>联系电话：400-110-9600</p>
    <p>联系邮箱：kefu@yuetao.group</p>
    <p>引言</p>
    <p>
      欢迎您使用悦淘！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《悦淘隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
    </p>
    <p>本隐私政策将帮助您了解以下内容：</p>
    <p>一、我们如何收集和使用您的个人信息</p>
    <p>二、我们如何使用 Cookies 和同类技术</p>
    <p>三、我们如何共享、转让、公开披露您的个人信息</p>
    <p>四、我们如何保护和保存您的个人信息</p>
    <p>五、您如何管理个人信息</p>
    <p>六、未成年人的个人信息保护</p>
    <p>七、通知和修订</p>
    <p>八、如何联系我们</p>
    <p>
      <strong>
        请您特别注意：本隐私政策适用于悦淘提供的产品或服务。如我们关联公司的产品或服务中使用了悦淘提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。
      </strong>
    </p>
    <p>
      <strong>
        本隐私政策与您所使用的悦淘服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。
      </strong>
    </p>

    <h3><strong>一、我们收集了哪些信息</strong></h3>
    <p>
      <strong>个人信息</strong
      >是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。<strong>敏感个人信息</strong>是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（我们将在本政策中对涉及到的敏感个人信息以<strong>粗体</strong>进行显著标识）。
    </p>
    <p>
      我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。
      <strong
        >如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。</strong
      >
    </p>
    <h4><strong>（一）您须授权我们收集和使用您个人信息的情形</strong></h4>
    <p>
      <strong>
        我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
      </strong>
    </p>
    <p><strong> 1、实现网上购物所必须的功能 </strong></p>

    <p>
      当您注册悦淘账户时，您至少需要向我们提供您准备使用的<strong>悦淘账户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱）</strong>，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效，通过SIM卡信息识别并显示对应的运营商。您的账户名为您的默认昵称，您可以修改和补充您的昵称、性别、生日、兴趣爱好以及您的实名认证相关信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用网上购物的基本功能。
    </p>

    <p>
      当您成功注册悦淘账号后，可以使用悦淘账号登录“悦淘商城”，登录时您需要向我们提供您的悦淘账号信息。若您不提供这类信息，您将无法登录和在“悦淘商城”购买商品，此时您可使用浏览和搜索功能，我们将不会收集您的上述个人信息。
    </p>
    <p><strong> （2）商品/服务信息展示和搜索 </strong></p>
    <p>
      <strong>
        为了让您快速地找到您所需要的商品，我们可能会收集您使用我们的产品与/或服务的设备信息（包括设备名称、设备型号、MAC地址及IMEI、设备识别码、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、设备所在位置相关信息（包括您授权的GPS位置信息以及WLAN接入点、蓝牙和基站传感器信息）、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。如果您不想看到推荐的商品或服务等信息，您可以通过长按被推荐的商品或服务图片，根据提示弹窗选择屏蔽相关商品或服务类目。
      </strong>
    </p>
    <p>
      您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品或服务。
    </p>
    <p>
      <strong>
        请您注意，单独的设备信息、浏览器类型或仅搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的单独的设备信息、浏览器类型或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的设备信息、浏览器类型或搜索关键词信息作为您的个人信息，按照本隐私政策对其进行处理与保护。
      </strong>
    </p>

    <p>
      为了将您感兴趣的商品或服务信息展示给您，或在您搜索时向您展示您可能希望找到的商品，我们可能会收集您的<strong>订单信息、浏览信息、您的兴趣爱好</strong>（您可以在账户设置中选择）进行数据分析以形成用户画像。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。如果您不希望受个性化内容的影响，您可以在商品展示页选择按照分类进行浏览和查找商品和服务；我们还在搜索结果中向您提供了按照价格、销量排序等不针对个人特征的选项；未经您的明确同意，我们不会将您的相关个人信息提供给第三方。
    </p>
    <p><strong> （3）下单及订单管理 </strong></p>

    <p>
      当您准备对您购物车内的商品进行结算时，悦淘系统会生成您购买该商品的订单。<strong
        >您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式；您可以另外填写收货人的联系电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。</strong
      >
    </p>
    <p>
      <strong>在实现部分特殊类型商品交易或服务时还需要您提供其他必要信息</strong
      >，例如您在悦淘上办理预订机票、火车票、酒店、跟团旅行、购买手机号卡、跨境交易等业务时，您可能需要根据国家法律法规或服务提供方（包括票务销售方、酒店、旅行社及其授权的代理方、基础电信运营商、移动转售运营商、海关等）的要求<strong
        >提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的出生证明、户口本、身份证、军官证、护照、驾驶证等载明您身份信息的证件复印件或号码）、您本人的照片或视频、姓名、电话号码。这些订单中将可能包含您的行程、酒店地址、您指定的服务地址或设备安装地址（可能是您的家庭地址）信息。</strong
      >
    </p>

    <p>
      上述所有信息构成您的“<strong>订单信息</strong>”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
    </p>

    <p><strong> （4）支付功能 </strong></p>

    <p>
      在您下单后，您可以选择悦淘的关联方或与悦淘合作的第三方支付机构（包括支付宝支付、微信支付及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，
      <strong
        >但我们需要将您的悦淘订单号与交易金额信息，包括您的银行卡号、有效期、还款金额在内的银行卡（含储蓄卡、信用卡）支付必要信息与这些支付机构共享以实现其确认您的支付指令并完成支付，以及您开通、使用悦淘钱包的账户金额信息，以便您能正常使用钱包功能</strong
      >。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
    </p>

    <p><strong> （5）交付产品与/或服务功能 </strong></p>

    <p>
      在当您下单并选择货到付款或在线完成支付，或选择获取试用品、奖品后，悦淘、悦淘的关联方、供应商、与悦淘合作的商家或第三方配送公司（以下称“配送公司”）将为您完成订单的交付。您知晓并同意，
      <strong
        >悦淘、悦淘的关联方、供应商、与悦淘合作的商家或配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。</strong
      >
    </p>

    <p>
      我们的配送员在为您提供配送服务的同时，<strong>基于某些业务法律要求实名认证的需求，会协助您完成实名认证</strong>，如您购买手机号卡服务时。我们在此环节会使用您的身份证通过国家有权认证机构的专有设备对您的身份进行核验，我们不会收集您的身份证信息，且我们的配送员均须遵守公司保密制度的规定。
    </p>
    <p><strong> （6）客服与售后功能 </strong></p>
    <p>我们的电话客服和售后功能会使用您的账号信息和订单信息。</p>
    <p>
      为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您<strong>核验您的身份</strong>。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的<strong>订单信息</strong>。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
    </p>
    <p>
      若您有获赠或购买商品运费险，您在退换货时所产生的运费可能申请由运费险服务提供商进行赔付，为实现投保和理赔，<strong
        >我们将收集或查询您购买商品的质保起止期、退换货商品快递公司、快递单号、退货地址、投递时间信息。</strong
      >
    </p>

    <p><strong> 2、保障交易安全所必须的功能 </strong></p>
    <p>
      为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的<strong
        >设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、IMSI、
        SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险</strong
      >，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对悦淘系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用悦淘服务过程中对您的服务及账号安全进行保护。
    </p>

    <h4><strong>（二）您可自主选择提供的个人信息的情形</strong></h4>
    <p>
      1、为使您购物更便捷或更有乐趣，从而提升您在悦淘的网上购物体验，我们的以下扩展功能中可能会收集和使用您的个人信息。<strong>如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用为您带来购物乐趣的扩展功能或在购买某些商品时需要重复填写一些信息</strong>。这些扩展功能包括：
    </p>
    <p>
      <strong
        >（1）基于位置信息的个性化推荐功能（LOCATION）：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的产品与/或服务</strong
      >，例如向您推荐离您最近的可选酒店，向您展示可选择的快速配送服务。
    </p>
    <p>
      <strong>（2）基于摄像头（相机）的扩展功能（CAMERA）</strong
      >：您可以使用这项扩展功能完成视频拍摄、拍照、扫码以及人脸识别登录的功能。<strong>当您使用人脸识别登录时我们会收集您的面部信息，且在您授权范围内限制使用</strong>。未来我们可能会将人脸识别技术应用于更多场景，届时我们会再次与您确认您是否愿意我们使用您的面部信息来实现这些扩展功能。
    </p>
    <p>
      <strong>（3）基于图片/视频上传的扩展功能</strong
      >：您可以在悦淘上传您的照片/视频来实现拍照购物功能和晒单、评价及问答功能，<strong
        >我们会使用您所上传的图片或视频来识别您需要购买的产品与/或服务，或者使用包含您所上传图片或视频的晒单、评价及问答内容。</strong
      >
    </p>
    <p>
      <strong>（4）基于语音技术的扩展功能（READ_AUDIO）</strong
      >：您可以直接使用麦克风来进行语音购物或与我们的客服进行咨询和互动。<strong
        >在这些功能中我们会收集您的录音内容以识别您的购物需求以及客服与售后需求。</strong
      >
    </p>
    <p>
      <strong>（5）基于通讯录信息的扩展功能（READ_CONTACTS）</strong
      >：在您同意开启通讯录权限后<strong>我们将收集您的通讯录信息以方便您在购物时不需再手动输入您通讯录中联系人的信息（例如您可以直接为通讯录中的电话号码充值）</strong>。为了增加您购物时的社交乐趣，在获得您的同意时我们也可以判断您的好友是否也同为悦淘用户，并在悦淘为你们的交流建立联系。
    </p>
    <p>
      <strong>（6）基于日历权限的扩展功能（READ_CALENDAR）</strong
      >：当您使用悦淘相关服务时，您可以通过开启日历权限便捷<strong>管理您的自定义事项、设定重要事项提醒</strong>。若您不开启此权限，您将无法使用日历管理相关的功能，但不影响您使用其他服务。
    </p>
    <p>
      <strong>（7）基于读取电话状态的扩展功能(READ_PHONE_STATE)</strong
      >：当您运行悦淘APP时，我们会向您申请获取此权限，目的是<strong
        >正常识别您的本机识别码，以便完成安全风控、进行统计和服务推送。</strong
      >
    </p>
    <p>
      <strong
        >（8）基于读取/写入外置存储器的扩展功能(WRITE_EXTERNAL_STORAGE)( READ_EXTERNAL_STORAGE)</strong
      >：在您开启我们可读取/写入您设备存储的权限后，我们将自您设备存储空间内<strong>读取或向其中写入图片、文件、崩溃日志信息等必要信息</strong>，用于向您提供信息发布或在本地记录崩溃日志信息等功能，保障客户端的稳定运行。
    </p>
    <p>
      <strong>（9）基于面容ID/触控ID权限的扩展功能</strong
      >：若您使用支持人脸识别的苹果设备，您可以使用悦淘APP中的面容ID支付、面容ID解锁功能；若您使用支持指纹识别的苹果设备，您可以使用悦淘APP中的指纹支付、指纹解锁功能。<strong>您需要在您的设备上提前录入面容信息或指纹信息，并在使用服务时在您的设备上完成信息验证</strong>。我们需要使用您的面容ID/触控ID权限以便接收验证结果，但我们并不收集您的面容ID/触控ID信息。若您不开启此权限，您将无法使用前述服务，但不影响您选择其他支付或解锁方式。
    </p>
    <p>
      <strong>（10）基于NFC的扩展功能</strong
      >：当您使用Android手机选择<strong>闪付卡</strong>进行支付时，需要开启您的NFC权限。若您不开启此权限，您将无法使用前述服务，但不影响您选择其他支付方式。
    </p>
    <p>
      <strong>（11）基于剪切板的扩展功能</strong
      >：当您复制商品信息、标题、短信验证码，分享或接收被分享信息时，您的剪切板功能可能会被调用。剪切板信息仅会在您的设备上进行处理，我们不会存储您的剪切板信息用于任何其他途径。
    </p>

    <p>
      2、上述扩展功能可能需要您在您的设备中向我们开启您的<strong>地理位置（位置信息）、相机（摄像头）、相册（图片库）、蓝牙、日历、通讯录、电话状态、外部存储、面容ID/触控ID、NFC权限</strong>，以实现这些功能所涉及的信息的收集和使用。您可以在悦淘APP或设备系统中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭（我们会指引您在您的设备系统中完成设置）。
      <strong
        >请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理</strong
      >。除此以外，我们在相关业务功能中可能还需要您开启设备的其他访问权限，<strong
        >详细权限和使用目的如下：</strong
      >
    </p>
    <p>
      <strong>（1）android.permission.INTERNET（访问网络权限）：</strong
      >实现应用程序联网
    </p>
    <p>
      <strong
        >（2）android.permission.ACCESS_WIFI_STATE（获取WiFi状态权限）：</strong
      >监控网络变化，提示用户当前网络环境
    </p>
    <p>
      <strong
        >（3）android.permission.ACCESS_NETWORK_STATE（获取网络状态权限）：</strong
      >监控网络变化，提示用户当前网络环境
    </p>
    <p>
      <strong>（4）android.permission.VIBRATE（使用振动权限）：</strong
      >允许手机振动
    </p>
    <p>
      <strong>（5）android.permission.WAKE_LOCK（唤醒锁定权限）：</strong
      >允许程序在手机屏幕关闭后后台进程仍然运行，保持屏幕唤醒
    </p>
    <p>
      <strong
        >（6）android.permission.RESTART_PACKAGES（结束系统任务权限）：</strong
      >重新启动应用
    </p>
    <p>
      <strong
        >（7）android.permission.MODIFY_AUDIO_SETTINGS（修改声音设置权限）：</strong
      >修改全局音频设置，例如调节音量和用于输出的扬声器
    </p>
    <p>
      <strong>（8）android.permission.BLUETOOTH（使用蓝牙权限）：</strong
      >小程序蓝牙功能模块使用
    </p>
    <p>
      <strong
        >（9）(android.permission.BLUETOOTH_ADMIN)（蓝牙管理权限）：</strong
      >小程序蓝牙功能模块使用
    </p>
    <p>
      <strong
        >（10）android.permission.SYSTEM_ALERT_WINDOW（悬浮窗权限）：</strong
      >观看直播、短视频悬浮窗播放
    </p>
    <p>
      <strong
        >（11）android.permission.ACCESS_BACKGROUND_LOCATION（支持后台访问位置权限）：</strong
      >保持持续定位能力
    </p>
    <p>
      <strong>（12）android.permission.WRITESYSTEM（读写系统设置权限）：</strong
      >允许应用读写系统设置项
    </p>
    <p>
      <strong>（13）android.permission.GET_TASKS（获取任务信息权限）：</strong
      >允许应用获取当前或最近运行的应用
    </p>
    <p>
      <strong
        >（14）android.permission.CHANGE_WIFI_STATE（改变WiFi状态权限）：</strong
      >允许应用改变WiFi状态
    </p>

    <h4>
      <strong
        >（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意： </strong
      >
    </h4>

    <p><strong>1、与国家安全、国防安全有关的；</strong></p>
    <p><strong>2、与公共安全、公共卫生、重大公共利益有关的；</strong></p>
    <p><strong>3、与犯罪侦查、起诉、审判和判决执行等有关的；</strong></p>
    <p>
      <strong
        >4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</strong
      >
    </p>
    <p>
      <strong
        >5、所收集的个人信息是个人信息主体或监护人自行向社会公众公开的；</strong
      >
    </p>
    <p>
      <strong
        >6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</strong
      >
    </p>
    <p><strong>7、根据您的要求签订合同所必需的；</strong></p>
    <p>
      <strong
        >8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</strong
      >
    </p>
    <p><strong>9、为合法的新闻报道所必需的；</strong></p>
    <p>
      <strong
        >10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</strong
      >
    </p>
    <p><strong>11、法律法规规定的其他情形。</strong></p>

    <h4><strong>（四）我们从第三方获得您个人信息的情形</strong></h4>

    <p>
      我们可能从第三方获取您授权共享的<strong>账户信息（头像、昵称及授权页面提示的其他信息）</strong>，并在您同意本隐私政策后将您的第三方账户与您的悦淘账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。您也可以使用本机号码来快捷登录，我们会将您的网络信息提供给运营商完成注册或登录。
    </p>

    <h4>
      <strong
        >（五）悦淘APP涉及嵌入第三方代码、插件传输个人信息的情形逐项列举，见备注说明</strong
      >
    </h4>
    <h4><strong>（六）您个人信息使用的规则</strong></h4>

    <p>
      1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
    </p>
    <p>
      2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。<strong>请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用</strong>。
    </p>
    <p>
      3、<strong
        >请您了解并同意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息，具体请详见本隐私政策附件《悦淘帐号注销说明》。</strong
      >
    </p>
    <p>
      4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
    </p>
    <p>
      5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
    </p>
    <p>
      6、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会实现征求您的明示同意。<strong>请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务</strong>。
    </p>

    <h3><strong>二、我们如何使用Cookies和同类技术</strong></h3>
    <h5>（一）Cookies的使用</h5>
    <p>
      1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。
    </p>
    <p>
      2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问悦淘网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
    </p>
    <h5>（二）网络Beacon和同类技术的使用</h5>
    <p>
      除Cookies 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素"
      GIF 文件或 "网络 Beacon"）。我们使用网络Beacon的方式有：
    </p>
    <p>
      1、通过在悦淘网站上使用网络Beacon，计算用户访问数量，并通过访问 Cookies 辨认注册的悦淘用户。
    </p>
    <p>2、通过得到的Cookies信息，为您提供个性化服务。</p>
    <h3>三、我们如何共享、转让、公开披露您的个人信息</h3>
    <h5>（一）共享</h5>
    <p>
      <strong
        >1、我们不会与悦淘以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</strong
      >
    </p>

    <p>（1）事先获得您明确的同意或授权；</p>
    <p>
      （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
    </p>
    <p>
      （3）在法律法规允许的范围内，为维护悦淘、悦淘的关联方或合作伙伴、您或其他悦淘用户或社会公众利益、财产或安全免遭损害而有必要提供；
    </p>
    <p>
      （4）
      只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
    </p>
    <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p>
      （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
    </p>
    <p>（7）基于学术研究而使用；</p>
    <p>（8）基于符合法律法规的社会公共利益而使用。</p>
    <p>
      2、为实现基于悦淘账户向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账户与交易安全，<strong
        >我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私政策的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</strong
      >
    </p>
    <p>
      <strong
        >3、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途</strong
      >。我们的合作伙伴包括以下类型：
    </p>
    <p>
      （1）商品或专业技术服务的供应商。我们<strong>可能会将您的个人信息共享给支持我们功能的第三方</strong>。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；当您下单的商品或服务由供应商直接发货或提供服务时，我们需要将您订单中的订购信息和收货信息共享给供应商，以指示其交付商品或提供服务。
    </p>
    <p>
      （2）第三方商家。<strong>我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务</strong>。
    </p>
    <p>
      （3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。<strong>我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人</strong>。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
    </p>
    <p>
      4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们推出了云鼎服务，在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。关于我们接入的合作伙伴等第三方的SDK目录见备注说明。
    </p>
    <p>
      5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护悦淘、您或其他悦淘客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
    </p>
    <h5>（二）转让</h5>
    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>1、事先获得您明确的同意或授权；</p>
    <p>
      2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
    </p>
    <p>
      3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
    </p>
    <p>
      <strong
        >4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</strong
      >
    </p>
    <h5>（三）公开披露</h5>
    <p>
      我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
    </p>
    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p>
      2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
    </p>

    <h3>四、我们如何保护和保存您的个人信息</h3>
    <h5>（一）我们保护您个人信息的技术与措施</h5>
    <p>
      我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
    </p>
    <p>1、运行中进程信息安全保护</p>
    <p>
      我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护相关产品或服务的正常稳定运行，保护你或其他用户或公众的安全及合法利益，我们会收集如下必要信息：
    </p>
    <p>
      （1）为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、IP
      地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。
    </p>
    <p>
      （2）为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
    </p>
    <p>
      （3）我们可能使用你的帐号信息、设备信息、服务日志信息，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断帐号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
    </p>

    <p>2、设备信息收集与数据服务</p>
    <p>我们基于本隐私政策收集的个人信息，经去标识化或匿名化处理后，形成数据产品，为各垂直行业提供数据解决方案。包括月狐数据、金融科技、iAudience、极光调研、极光联盟等。当您使用我们的数据服务时，您可能需要向我们提供您终端用户加密后的手机号码、IMEI，以便我们通过数据分析，反馈分析结果。请您在向我们申请前述数据服务时，提前向您的终端用户告知并获得合法授权。在金融科技服务场景中，我们将会和有资质的征信机构和金融机构合作，对您的终端用户金融风控信息进行分析。
提请您注意并知悉，极光SDK为实现上述业务功能，收集您终端用户“设备信息”、“网络信息”和/或“位置信息”的前提是，终端用户授权同意开启“网络访问权限”、“设备信息权限”和/或“位置权限”。 如果您的终端用户不想被收集上述信息，可以通过关闭“网络访问权限”、“设备信息权限”和/或“位置权限”实现。
您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正／修改、删除、注销、撤回同意、索取信息的请求。
   </p>
   <p><strong>3、数据安全技术措施</strong></p>
    <p>
      我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
    </p>
    <p>
      悦淘的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
    </p>
    <p>悦淘采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</p>
    <p>
      在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。
    </p>
    <p>
      悦淘采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
    </p>
    <p>悦淘采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</p>
    <p><strong>4、悦淘为保护个人信息采取的其他安全措施</strong></p>
    <p>
      悦淘通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
    </p>
    <p>悦淘通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
    <p>
      <strong
        >5、我们仅允许有必要知晓这些信息的悦淘及悦淘关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与悦淘的合作关系。</strong
      >
    </p>
    <p>
      <strong
        >6、我们会采取一切合理可行的措施，确保未收集无关的个人信息。</strong
      >
    </p>
    <p>
      7、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过悦淘与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
    </p>
    <p><strong>8、安全事件处置</strong></p>
    <p>
      为应对个人信息泄露、损毁和丢失等可能出现的风险，悦淘制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。悦淘也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
    </p>
    <p>
      一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>
    <p>
      9、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p>
      10、如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。<strong
        >如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策【八、如何联系我们】中约定的联系方式联络我们，以便我们采取相应措施。</strong
      >
    </p>
    <h5>（二）您个人信息的保存</h5>
    <p>
      1、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
    </p>
    <p>
      <strong
        >2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。</strong
      >
    </p>
    <p>
      <strong
        >3、请您注意，当您成功注销悦淘账户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。</strong
      >
    </p>
    <p>
      <strong
        >4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</strong
      >
    </p>

    <h3>五、您如何管理您的个人信息</h3>
    <p>
      悦淘非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
    </p>
    <p><strong>1、访问和更正您的个人信息</strong></p>
    <p>除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</p>
    <p>
      （1）您的账户信息：移动端具体路径为：账户名称、个人资料信息：首页--“我的”进入我的悦淘--右上角“设置”进入账号设置—点击头像进入个人信息页账户密码、电话号码、安全信息：首页--“我的”进入我的悦淘--右上角“设置按钮”--账户与安全；你也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息），如您需要变更您的实名认证信息，您可拨打4001109600服务热线申请变更。
    </p>
    <p>
      （2）您的收货信息：移动端具体路径为：首页--“我的”进入我的悦淘--右上角“设置按钮”进入账号设置--地址管理。您也可以将最常用的收货地址设置为默认地址，如此，若您下次购买商品时未更改收货地址，您的商品会配送到该默认地址。
    </p>
    <p>
      （3）您的订单信息：移动端具体路径为：移动端首页--“我的”进入我的悦淘--我的订单/待收款/待收货/退换售后。<strong
        >您可以选择删除已经完成和已取消的订单来删除您的订单信息，但这样可能导致我们无法准确判断您的购买信息而难以提供相应的售后服务，您也无法对删除的订单使用我们的晒单和评价功能。</strong
      >
    </p>
    <p>
      （4）您的浏览信息：移动端路径为：搜索历史记录：首页--“我的”进入我的悦淘--浏览记录；
    </p>
    <p>（5）您的评论信息：移动端路径为：我的订单--待评价--评价中心。</p>
    <p>
      （6）您的发票信息：移动端路径为首页--“我的”进入我的订单—订单右下角“发票”进入账号设置--增票资质。
    </p>
    <p>
      （7）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
    </p>
    <p>
      （8）您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。
    </p>
    <p><strong> 2、删除您的个人信息</strong></p>
    <p>
      您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。
    </p>
    <p><strong> 在以下情形中，您可以向我们提出删除个人信息的请求：</strong></p>
    <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
    <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p>（3）如果我们处理个人信息的行为违反了与您的约定；</p>
    <p>（4）如果您不再使用我们的产品或服务，或您注销了悦淘账号；</p>
    <p>
      （5）如果我们终止服务及运营。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。
    </p>
    <p><strong>3、改变您授权同意的范围或撤回您的授权</strong></p>
    <p>
      <strong
        >您可以通过删除信息、关闭设备功能、在悦淘网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</strong
      >
    </p>
    <p>
      <strong
        >请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</strong
      >
    </p>
    <p><strong>4、注销账户</strong></p>
    <p>
      <strong
        >您可以在我们的产品中直接申请注销账户。您可以通过移动端APP访问我的--账户设置-账户与安全-注销服务-完成账号注销；关于您注销账户的方式以及您应满足的条件，请详见《悦淘账户注销须知》。您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。</strong
      >
    </p>
    <p><strong>5、获取您的个人信息副本</strong></p>
    <p>
      您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。
    </p>
    <p>
      <strong
        >6、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</strong
      >
    </p>
    <p>
      （1）您可以通过移动端APP“消息中心-消息设置”设置是否接受我们通过“通知”推动给您的商品和促销信息。
    </p>
    <p>
      （2）我们会与第三方的平台或媒体（“平台”）合作基于您的个人信息向您推荐个性化的商品。这些个人信息包括诸如在本网站的产品与/或服务使用情况、访问本网站关联公司网站的情况及您在您的账户设置中填写的兴趣爱好。平台仅向我们提供了展示商品的窗口，窗口内容的链接是悦淘站内的个性化商品展示信息，由悦淘进行管理，因此我们不会向广告商提供您的任何个人信息。而且我们在推荐窗内设置了屏蔽功能，您可选择屏蔽您不喜欢的广告。如您不愿意接受悦淘在单个平台上的推荐服务，请联系平台进行关闭。您知晓并理解平台是自主经营的实体，悦淘无法对其进行管理。
    </p>
    <p>
      （3）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性取向等相关敏感内容的促销或商品信息给您。
    </p>
    <p><strong>7、响应您的请求</strong></p>
    <p>
      如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为悦淘存在任何违反法律法规或与您关于个人信息的收集或使用的约定，<strong>您均可以发送电子邮件至kefu@yuetao.group</strong>或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
    <p>（1）与个人信息控制者履行法律法规规定的义务相关的；</p>
    <p>（2）与国家安全、国防安全有关的；</p>
    <p>（3）与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>（4）与犯罪侦查、起诉和审判和执行判决等有关的；</p>
    <p>（5）有充分证据表明您存在主观恶意或滥用权利的；</p>
    <p>
      （6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
    <p>（8）涉及商业秘密的。</p>
    <h3>六、未成年人的个人信息保护</h3>
    <p>
      1、悦淘非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，<strong>应事先取得您监护人的同意</strong>。悦淘根据国家相关法律法规的规定保护未成年人的个人信息。
    </p>
    <p>
      2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </p>
    <p>
      3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
    </p>
    <p>
      4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：
    </p>
    <p>
      （1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。
    </p>
    <p>
      （2）当您作为监护人为被监护的儿童选择使用悦淘相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享商品相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。
    </p>
    <p>
      （3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。
    </p>
    <h3>七、通知和修订</h3>
    <p>
      1、为给您提供更好的服务以及随着悦淘业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在悦淘网站、悦淘移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问悦淘以便及时了解最新的隐私政策。
    </p>
    <p>
      2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
    </p>
    <p>本隐私政策所指的重大变更包括但不限于：</p>
    <p>
      （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p>
      （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p>（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
    <p>3、我们还会将本隐私政策的旧版本存档，供您查阅。</p>
    <p>
      4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。您可以在悦淘APP通过“我的-账户设置-关于悦淘APP-隐私政策”中查看本政策。
    </p>
    <h3>八、如何联系我们</h3>
    <p>
      1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。
    </p>
    <p>个人信息保护负责人邮箱tousu@yuetao.group</p>
    <p>联系电话：【4001109600】</p>
    <p>联系地址：北京朝阳区义安门37栋111号</p>
    <p>
      2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
    </p>

    <p>
      附：个人信息及敏感个人信息示例（非悦淘APP或网站所收集的个人信息，仅作示例说明）
    </p>
    <p>
      个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
    </p>

    <p>
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-12/10/yuelvhui099NIFyalQ1631414781.png"
        alt=""
      />
    </p>

    <p>备注说明</p>
    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>SDK</strong><strong>&nbsp;</strong><strong>名称</strong>
            </p>
          </td>
          <td>
            <p><strong>使用场景</strong></p>
          </td>
          <td>
            <p><strong>第三方公司名称</strong></p>
          </td>
          <td>
            <p><strong>使用目的</strong></p>
          </td>
          <td>
            <p><strong>收集方式/共享方式</strong></p>
          </td>
          <td>
            <p><strong>个人信息收集类型</strong></p>
          </td>
          <td>
            <p><strong>隐私保护说明</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p>极光SDK（cn.jiguang）</p>
          </td>
          <td>
            <p>移动消息推送</p>
          </td>
          <td>
            <p>深圳市和讯华谷信息技术有限公司</p>
          </td>
          <td>
            <p>及时告知用户商品服务信息</p>
          </td>
          <td>
            <p>嵌入第三方 sdk ， sdk 收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备标识符：IMEI、IDFA、Android ID、GID、
              MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息。
            </p>
          </td>
          <td>
            <p>https://www.jiguang.cn/license/privacy</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>京东开普勒</p>
          </td>
          <td>
            <p>商品供应链</p>
          </td>
          <td>
            <p>重庆京东海嘉电子商务有限公司</p>
          </td>
          <td>
            <p>提供商品供应链</p>
          </td>
          <td>
            <p>API入库商品</p>
          </td>
          <td>
            <p>设备信息（ IMEI / MAC / Android ID ），设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息</p>
          </td>
          <td>
            <p>
              https://kepler.jd.com/console/docCenterCatalog/docContent?channelId=54
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>友盟&nbsp;SDK</p>
          </td>
          <td>
            <p>数据分析服务合作方</p>
          </td>
          <td>
            <p>友盟同欣（北京）科技有限公司</p>
          </td>
          <td>
            <p>
              提供用户行为数据、&nbsp;app&nbsp;运营数据的采集与可视化分析，帮助公司更好的分析决策实现业务增长；收集整理&nbsp;app&nbsp;崩溃日志，提供&nbsp;app&nbsp;稳定性
            </p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备信息（ IMEI / MAC / Android ID / IDFA / OpenUDID / GUID / SIM 卡
 IMSI ／地理位置 /获取设备序列号等）
            </p>
          </td>
          <td>
            <p>
              <a href="http://www.umeng.com/policy"
                >https://www.umeng.com/policy</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>阿里百川&nbsp;SDK</p>
          </td>
          <td>
            <p>一个开放的移动互联网应用开发及商业化平台</p>
          </td>
          <td>
            <p>阿里巴巴网络技术有限公司</p>
          </td>
          <td>
            <p>提供&nbsp;CPS&nbsp;电商业务</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备&nbsp;MAC&nbsp;地址、唯一设备标识码、&nbsp;app&nbsp;内用户操作记录、用户淘宝信息
            </p>
          </td>
          <td>
            <p>
              <a
                href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_b2b/suit_bu1_b2b201703271337_94551.html"
                >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_b2b/suit_bu1_b2b201703271337_94551.html</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>支付宝&nbsp;SDK</p>
          </td>
          <td>
            <p>支付担保交易平台</p>
          </td>
          <td>
            <p>支付宝（中国）网络技术有限公司</p>
          </td>
          <td>
            <p>提供支付功能</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备型号、&nbsp;IP&nbsp;地址、设备软件版本信息、设备识别码、设备标识符、所在地区、网络使用习惯以及其他支付宝服务相关的日志信息
            </p>
          </td>
          <td>
            <p>
              <a href="https://render.alipay.com/p/c/k2cx0tg8"
                >https://render.alipay.com/p/c/k2cx0tg8</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>微信&nbsp;SDK</p>
          </td>
          <td>
            <p>
              是中国最大的互联网综合服务提供商之一&nbsp;,&nbsp;也是中国服务用户最多的互联网企业之一
            </p>
          </td>
          <td>
            <p>深圳市腾讯计算机系统有限公司</p>
          </td>
          <td>
            <p>提供微信登录分享支付等功能</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备型号、操作系统、唯一设备标识符、登陆&nbsp;IP&nbsp;地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据
            </p>
          </td>
          <td>
            <p>
              <a
                href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy"
                >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>穿山甲SDK（open_ad_sdk）</p>
          </td>
          <td>
            <p>移动广告合作方</p>
          </td>
          <td>
            <p>北京巨量引擎网络技术有限公司</p>
          </td>
          <td>
            <p>提供个性化互联网广告</p>
          </td>
          <td>
            <p>第三方SDK接入</p>
          </td>
          <td>
            <p>
              【必要信息】<br />
              设备信息：<br />
              【Android】设备品牌、型号、软件系统版本相关信息、oaid等基础信息<br />
              【iOS】设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息<br />
              应用信息：<br />
              开发者应用名、应用包名、版本号等信息<br />
              其他信息：<br />
              运营商信息、设备时区<br />
              【可选信息】<br />
              （您可以基于广告投放效果优化的目的需要可选择是否提供给穿山甲）<br />
              【Android】无线网SSID名称，WIFI路由器MAC地址、设备的MAC地址、设备IMEI、设备地理位置<br />
              以下仅适用iOS2800以下版本：<br />
              剪切板调用：<br />
              openudid可选信息获取（系通过调用剪切板获取并用于广告投放和反作弊）<br />
              APP崩溃检测（系通过调用剪切板实现，用于检测是否会因SDK导致APP运行不稳定或其他运行异常状态）<br />
              上述调用仅适用于广告投放和反作弊相关必要场景，不会获取隐私信息。<br />
              以下仅适用于iOS3200以下版本：<br />
              【iOS】无线网SSID名称，WIFI路由器MAC地址，设备的MAC地址。
            </p>
          </td>
          <td>
            <p>
              <a href="https://www.pangle.cn/privacy/partner"
                >https://www.pangle.cn/privacy/partner</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>广点通&nbsp;SDK</p>
          </td>
          <td>
            <p>广告平台</p>
          </td>
          <td>
            <p>腾讯科技</p>
          </td>
          <td>
            <p>提供广告</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              <a href="https://privacy.qq.com/noun.htm"
                >日志信息、设备信息、软件信息、&nbsp;IP&nbsp;地址、服务日志信息、通讯日志信息、位置信息</a
              >
            </p>
          </td>
          <td>
            <p>
              <a href="https://www.qq.com/yszc.htm"
                >https://www.qq.com/yszc.htm</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>闪验&nbsp;（chuanglan-sms-operator） SDK </p>
          </td>
          <td>
            <p>闪验是一款帮助开发者实现一键验证功能的产品</p>
          </td>
          <td>
            <p>上海创蓝文化传播有限公司</p>
          </td>
          <td>
            <p>用于获取用户当前运营商手机号码，提供用户登录便捷性</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              设备信息：设备标识符（IMEI、Android ID、MAC、OAID等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息</p>
          </td>
          <td>
            <p>
              <a href="https://www.chuanglan.com/document/6110e48109fd9600010209d3/61bfec2520eb52000187938e"
                >https://www.chuanglan.com/document/6110e48109fd9600010209d3/61bfec2520eb52000187938e</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>中国移动SDK</p>
          </td>
          <td>
            <p>免密认证产品服务合作方</p>
          </td>
          <td>
            <p>中移互联网有限公司</p>
          </td>
          <td>
            <p>以手机号为基础进行一键式登录</p>
          </td>
          <td>
            <p>嵌入第三方SDK用于集成中国移动免密认证产品能力</p>
          </td>
          <td>
            <p>
              网络类型、网络制式、设备类型、系统、imsi（仅android）、idfv（仅iOS）
            </p>
          </td>
          <td>
            <p>
              <a href="http://wap.cmpassport.com/resources/html/contract.html"
                >http://wap.cmpassport.com/resources/html/contract.html</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>中国联通SDK</p>
          </td>
          <td>
            <p>免密认证产品服务合作方</p>
          </td>
          <td>
            <p>中国联合网络通信有限公司北京市分公司/小沃科技有限公司</p>
          </td>
          <td>
            <p>以手机号为基础进行一键式登录</p>
          </td>
          <td>
            <p>嵌入第三方SDK用于集成中国联通免密认证产品能力</p>
          </td>
          <td>
            <p>
              操作系统版本、手机型号、<br />
              设备ID、公网IP、私网IP
            </p>
          </td>
          <td>
            <p>
              <a href="https://ms.zzx9.cn/html/oauth/protocol2.html"
                >https://ms.zzx9.cn/html/oauth/protocol2.html</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>中国电信SDK</p>
          </td>
          <td>
            <p>免密认证产品服务合作方</p>
          </td>
          <td>
            <p>世纪龙信息网络有限责任公司</p>
          </td>
          <td>
            <p>以手机号为基础进行一键式登录</p>
          </td>
          <td>
            <p>嵌入第三方SDK用于集成中国电信免密认证产品能力</p>
          </td>
          <td>
            <p>
              操作系统类型（Android/iOS），操作系统版本号，设备品牌，设备型号，当前用户连接网络类型（WiFi，蜂窝数据），bundleId（仅iOS），本机IP地址
            </p>
          </td>
          <td>
            <p>
              <a
                href="https://e.189.cn/sdk/agreement/detail.do?isWap=true&amp;hidetop=true&amp;appKey="
                >https://e.189.cn/sdk/agreement/detail.do?isWap=true&amp;hidetop=true&amp;appKey=</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>北京有竹居SDK（AppLogSDK）</p>
          </td>
          <td>
            <p>移动广告合作方</p>
          </td>
          <td>
            <p>北京有竹居网络技术有限公司</p>
          </td>
          <td>
            <p>为穿山甲上报广告相关事件服务</p>
          </td>
          <td>
            <p>集成在穿山甲SDK内</p>
          </td>
          <td>
            <p>
              【必要信息】<br />
              设备信息：<br />
              【Android】设备品牌、型号、软件系统版本相关信息、oaid等基础信息<br />
              【iOS】设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息<br />
              应用信息&nbsp;：开发者应用名、应用包名、版本号等信息<br />
              其他信息：&nbsp;运营商信息、设备时区<br />
              <br />
              【可选信息】<br />
              （您可以基于数据分析目的需要可选择是否提供给数据漫游者）<br />
              【Android】无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、设备IMEI
            </p>
          </td>
          <td>
            <p>
              <a
                href="https://sf1-ttcdn-tos.pstatp.com/obj/zebra-public/privacy_agreement_1.pdf"
                >https://sf1-ttcdn-tos.pstatp.com/obj/zebra-public/privacy_agreement_1.pdf</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>高德定位&nbsp;SDK</p>
          </td>
          <td>
            <p>地理位置信息服务提供商</p>
          </td>
          <td>
            <p>高德软件有限公司</p>
          </td>
          <td>
            <p>提供用户当前地理位置信息</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>设备定位，获取设备序列号</p>
          </td>
          <td>
            <p>
              <a href="https://lbs.amap.com/home/terms/"
                >https://lbs.amap.com/home/terms/</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>百度定位&nbsp;SDK</p>
          </td>
          <td>
            <p>地理位置信息服务提供商</p>
          </td>
          <td>
            <p>北京百度网讯科技有限公司</p>
          </td>
          <td>
            <p>提供用户当前地理位置信息</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk</p>
          </td>
          <td>
            <p>WIFI&nbsp;信息、位置信息</p>
          </td>
          <td>
            <p>
              <a href="http://lbsyun.baidu.com/index.php?title=open/privacy"
                >http://lbsyun.baidu.com/index.php?title=open/privacy</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>极光推送&nbsp;SDK</p>
          </td>
          <td>
            <p>推送系统</p>
          </td>
          <td>
            <p>深圳市和讯华谷信息技术有限公司</p>
          </td>
          <td>
            <p>推送</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              收集终端用户&nbsp;&ldquo;&nbsp;设备信息&nbsp;&rdquo;&nbsp;、&nbsp;&ldquo;&nbsp;网络信息&nbsp;&rdquo;&nbsp;和&nbsp;/&nbsp;或&nbsp;&ldquo;&nbsp;位置信息
            </p>
          </td>
          <td>
            <p>
              <a href="https://www.jiguang.cn/license/privacy"
                >https://www.jiguang.cn/license/privacy</a
              >
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>华为&nbsp;SDK</p>
          </td>
          <td>
            <p>推送功能</p>
          </td>
          <td>
            <p>华为终端有限公司</p>
          </td>
          <td>
            <p>推送服务</p>
          </td>
          <td>
            <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>
          </td>
          <td>
            <p>
              Space&nbsp;ID&nbsp;（若使用分身空间功能）、设备相关信息（&nbsp;IMEI&nbsp;号，&nbsp;SerialNumber&nbsp;，&nbsp;Android&nbsp;ID&nbsp;，&nbsp;Google&nbsp;Advertising&nbsp;ID,&nbsp;&nbsp;手机&nbsp;Region&nbsp;设置，设备型号，手机电量，手机操作系统版本及语言等）、使用推送服务的应用信息（&nbsp;APP&nbsp;包名及版本号，是否在运行，&nbsp;&nbsp;&nbsp;是否在前台等）、推送&nbsp;sdk&nbsp;版本号、网络相关信息（&nbsp;IP&nbsp;或域名连接结果，当前网络类型）、消息发送结果，通知栏状态（如是否允许通知栏通知、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）等
            </p>
          </td>
          <td>
            <p>
              <a
                href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021"
                >https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</a
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <p>&nbsp;</p>

    <!--    <table>-->
    <!--      <tbody>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p><strong>SDK</strong><strong>&nbsp;</strong><strong>名称</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>合作伙伴类型</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>合作伙伴名称</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>合作目的</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>合作方式</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>共享个人信息字段</strong></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><strong>隐私政策链接</strong></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>京东开普勒</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>&nbsp;</p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>友盟&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>数据分析服务合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>友盟同欣（北京）科技有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供用户行为数据、&nbsp;app&nbsp;运营数据的采集与可视化分析，帮助公司更好的分析决策实现业务增长；收集整理&nbsp;app&nbsp;崩溃日志，提供&nbsp;app&nbsp;稳定性</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>设备&nbsp;MAC&nbsp;地址、唯一设备标识码、&nbsp;app&nbsp;内用户操作记录、&nbsp;app&nbsp;崩溃日志</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="http://www.umeng.com/policy">https://www.umeng.com/policy</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>阿里百川&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>一个开放的移动互联网应用开发及商业化平台</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>阿里巴巴网络技术有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供&nbsp;CPS&nbsp;电商业务</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>设备&nbsp;MAC&nbsp;地址、唯一设备标识码、&nbsp;app&nbsp;内用户操作记录、用户淘宝信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_b2b/suit_bu1_b2b201703271337_94551.html">https://terms.alicdn.com/legal-agreement/terms/suit_bu1_b2b/suit_bu1_b2b201703271337_94551.html</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>支付宝&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>支付担保交易平台</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>支付宝（中国）网络技术有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供支付功能</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>设备型号、&nbsp;IP&nbsp;地址、设备软件版本信息、设备识别码、设备标识符、所在地区、网络使用习惯以及其他支付宝服务相关的日志信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>微信&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>是中国最大的互联网综合服务提供商之一&nbsp;,&nbsp;也是中国服务用户最多的互联网企业之一</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>深圳市腾讯计算机系统有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供微信登录分享支付等功能</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>设备型号、操作系统、唯一设备标识符、登陆&nbsp;IP&nbsp;地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>穿山甲SDK（open_ad_sdk）</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>移动广告合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>北京巨量引擎网络技术有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供个性化互联网广告</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>第三方SDK接入</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>【必要信息】<br/>-->
    <!--            设备信息：<br/>-->
    <!--            【Android】设备品牌、型号、软件系统版本相关信息、oaid等基础信息<br/>-->
    <!--            【iOS】设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息<br/>-->
    <!--            应用信息：<br/>-->
    <!--            开发者应用名、应用包名、版本号等信息<br/>-->
    <!--            其他信息：<br/>-->
    <!--            运营商信息、设备时区<br/>-->
    <!--            【可选信息】<br/>-->
    <!--            （您可以基于广告投放效果优化的目的需要可选择是否提供给穿山甲）<br/>-->
    <!--            【Android】无线网SSID名称，WIFI路由器MAC地址、设备的MAC地址、设备IMEI、设备地理位置<br/>-->
    <!--            以下仅适用iOS2800以下版本：<br/>-->
    <!--            剪切板调用：<br/>-->
    <!--            openudid可选信息获取（系通过调用剪切板获取并用于广告投放和反作弊）<br/>-->
    <!--            APP崩溃检测（系通过调用剪切板实现，用于检测是否会因SDK导致APP运行不稳定或其他运行异常状态）<br/>-->
    <!--            上述调用仅适用于广告投放和反作弊相关必要场景，不会获取隐私信息。<br/>-->
    <!--            以下仅适用于iOS3200以下版本：<br/>-->
    <!--            【iOS】无线网SSID名称，WIFI路由器MAC地址，设备的MAC地址。</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://www.pangle.cn/privacy/partner">https://www.pangle.cn/privacy/partner</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>广点通&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>广告平台</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>腾讯科技</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供广告</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://privacy.qq.com/noun.htm">日志信息、设备信息、软件信息、&nbsp;IP&nbsp;地址、服务日志信息、通讯日志信息、位置信息</a></p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://www.qq.com/yszc.htm">https://www.qq.com/yszc.htm</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>闪验&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>闪验是一款帮助开发者实现一键验证功能的产品</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>上海创蓝文化传播有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>用于获取用户当前运营商手机号码，提供用户登录便捷性</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>会收集到&nbsp;IP&nbsp;地址、网卡（&nbsp;MAC)&nbsp;地址手机机型、系统类型、系统版本、网络环境、网关取号报错日志等，可能会收集到国际移动设备识别码（&nbsp;IMEI&nbsp;）、&nbsp;sim&nbsp;卡信息。</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://www.253.com/api_doc/yin-si-zheng-ce.html"-->
    <!--          >https://www.253.com/api_doc/yin-si-zheng-ce.html</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>中国移动SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>免密认证产品服务合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>中移互联网有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>以手机号为基础进行一键式登录</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方SDK用于集成中国移动免密认证产品能力</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>网络类型、网络制式、设备类型、系统、imsi（仅android）、idfv（仅iOS）</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="http://wap.cmpassport.com/resources/html/contract.html">http://wap.cmpassport.com/resources/html/contract.html</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>中国联通SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>免密认证产品服务合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>中国联合网络通信有限公司北京市分公司/小沃科技有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>以手机号为基础进行一键式登录</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方SDK用于集成中国联通免密认证产品能力</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>操作系统版本、手机型号、<br/>-->
    <!--            设备ID、公网IP、私网IP</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://ms.zzx9.cn/html/oauth/protocol2.html">https://ms.zzx9.cn/html/oauth/protocol2.html</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>中国电信SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>免密认证产品服务合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>世纪龙信息网络有限责任公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>以手机号为基础进行一键式登录</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方SDK用于集成中国电信免密认证产品能力</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>操作系统类型（Android/iOS），操作系统版本号，设备品牌，设备型号，当前用户连接网络类型（WiFi，蜂窝数据），bundleId（仅iOS），本机IP地址</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://e.189.cn/sdk/agreement/detail.do?isWap=true&amp;hidetop=true&amp;appKey=">https://e.189.cn/sdk/agreement/detail.do?isWap=true&amp;hidetop=true&amp;appKey=</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>北京有竹居SDK（AppLogSDK）</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>移动广告合作方</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>北京有竹居网络技术有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>为穿山甲上报广告相关事件服务</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>集成在穿山甲SDK内</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>【必要信息】<br/>-->
    <!--            设备信息：<br/>-->
    <!--            【Android】设备品牌、型号、软件系统版本相关信息、oaid等基础信息<br/>-->
    <!--            【iOS】设备品牌、型号、软件系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息<br/>-->
    <!--            应用信息&nbsp;：开发者应用名、应用包名、版本号等信息<br/>-->
    <!--            其他信息：&nbsp;运营商信息、设备时区<br/>-->
    <!--            <br/>-->
    <!--            【可选信息】<br/>-->
    <!--            （您可以基于数据分析目的需要可选择是否提供给数据漫游者）<br/>-->
    <!--            【Android】无线网SSID名称、WIFI路由器MAC地址、设备的mac地址、设备IMEI</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://sf1-ttcdn-tos.pstatp.com/obj/zebra-public/privacy_agreement_1.pdf">https://sf1-ttcdn-tos.pstatp.com/obj/zebra-public/privacy_agreement_1.pdf</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>高德定位&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>地理位置信息服务提供商</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>高德软件有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供用户当前地理位置信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>设备定位</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://lbs.amap.com/home/terms/">https://lbs.amap.com/home/terms/</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>百度定位&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>地理位置信息服务提供商</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>北京百度网讯科技有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>提供用户当前地理位置信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>WIFI&nbsp;信息、位置信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="http://lbsyun.baidu.com/index.php?title=open/privacy">http://lbsyun.baidu.com/index.php?title=open/privacy</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>极光推送&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>推送系统</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>深圳市和讯华谷信息技术有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>推送</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>收集终端用户&nbsp;&ldquo;&nbsp;设备信息&nbsp;&rdquo;&nbsp;、&nbsp;&ldquo;&nbsp;网络信息&nbsp;&rdquo;&nbsp;和&nbsp;/&nbsp;或&nbsp;&ldquo;&nbsp;位置信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a></p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      <tr>-->
    <!--        <td>-->
    <!--          <p>华为&nbsp;SDK</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>推送功能</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>华为终端有限公司</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>推送服务</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>嵌入第三方&nbsp;sdk&nbsp;，&nbsp;sdk&nbsp;收集传输个人信息</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p>Space&nbsp;ID&nbsp;（若使用分身空间功能）、设备相关信息（&nbsp;IMEI&nbsp;号，&nbsp;SerialNumber&nbsp;，&nbsp;Android&nbsp;ID&nbsp;，&nbsp;Google&nbsp;Advertising&nbsp;ID,&nbsp;&nbsp;手机&nbsp;Region&nbsp;设置，设备型号，手机电量，手机操作系统版本及语言等）、使用推送服务的应用信息（&nbsp;APP&nbsp;包名及版本号，是否在运行，&nbsp;&nbsp;&nbsp;是否在前台等）、推送&nbsp;sdk&nbsp;版本号、网络相关信息（&nbsp;IP&nbsp;或域名连接结果，当前网络类型）、消息发送结果，通知栏状态（如是否允许通知栏通知、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）等</p>-->
    <!--        </td>-->
    <!--        <td>-->
    <!--          <p><a-->
    <!--            href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021"-->
    <!--          >https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</a>-->
    <!--          </p>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--      </tbody>-->
    <!--    </table>-->

    <p>
      注：本隐私政策版本更新日期为2022年10月25日。
    </p>
    <p>
      悦淘网络科技（北京）有限公司
    </p>
    <p>2022年10月25日</p>
  </div>
</template>
<script>
export default {
  name: "Policy",
};
</script>
<style lang="less" scoped>
h1,
h2,
h3,
h4,
h5 {
  font-size: initial;
}

.PolicyBox {
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  padding: 15px;

  p {
    margin-bottom: 15px;
  }

  h2 {
    margin-bottom: 15px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 15px;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 12px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 12px;
  }

  span {
    display: inline-block;
  }

  table {
    border: 1px solid;
    max-width: 100%;
  }

  th,
  td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;

    p {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
}

.t-i-1 {
  text-indent: 1em;
}

.t-i-2 {
  text-indent: 2em;
}
</style>
